(async () => {
  // const shouldLoad = document.querySelector('[data-load-cart="true"]');
  try {
    if (CONFIG.enableMiddleware /* && shouldLoad*/) {
      require.ensure(
        [],
        (require) => {
          const purchaseMiddleware = require("./purchase-middleware").default;
          purchaseMiddleware();
        },
        "purchase-middleware-app"
      );
    }
  } catch (err) {
    console.error(err); // eslint-disable-line
  }
})();
